import React, {useEffect, useState} from 'react';
import './App.css';
import Steps from "./components/Steps";
import Animation from "./components/Animation";
import Confetti from 'react-confetti'

const App: React.FC = () => {
    const [currentStep, setCurrentStep] = useState(0)
    const [showConfetti, setShowConfetti] = useState(false)
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const onChangeStep = (step: number) => {
        setCurrentStep(step)
    }


    useEffect(() => {
        if (currentStep === 6) {
            setTimeout(() => {
                setShowConfetti(true)
            }, 2000)
            setTimeout(() => {
                setShowConfetti(false)
            }, 10000)
        } else {
            setShowConfetti(false)
        }
    }, [currentStep])

    return (
        <div className="App">
            {showConfetti &&
                <Confetti
                    width={windowSize.innerWidth}
                    height={windowSize.innerHeight}
                />
            }
            {windowSize.innerWidth > 800 ?
                <div className="fill">
                    <Steps onChangeStep={onChangeStep}/>
                    <Animation currentStep={currentStep}/>
                </div>
                :
                <div className="fill">
                    <Animation currentStep={currentStep}/>
                    <Steps onChangeStep={onChangeStep}/>
                </div>
            }
            <div className="footer">www.goplen.dev</div>
        </div>
    );
}

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

export default App;
