import React, {useState} from "react";

import "./Steps.css"

interface StepsProps {
    onChangeStep: (step: number) => void
}

const allSteps = [
    "Finn et ledig sted å parkere.",
    "Kjør bilen frem til den borterste bilen i luken slik at din fanger og bilens fanger står ovenfor hverandre.",
    "Sett på blinklys og sving rattet helt over.",
    "Rygg bakover helt til du kan se begge frontlysene til den bakre bilen i sidespilet.",
    "Rett opp hjulene slik at du kan rygge rett bakover og rygg helt til fronten av din bil er forbi fangeren til bilen foran.",
    "Sving rattet helt over og rygg helt til du nærmer deg bilen bak.",
    "Sving rattet helt over til den motsatte siden og kjør frem for å rette opp bilen."
]

const Steps: React.FC<StepsProps> = ({onChangeStep}) => {
    const [currentStep, setCurrentStep] = useState(0)

    return <div className="step">
        <div className="header_app">
            Lukeparkering
        </div>
        <div className="step-header"><b>Steg {currentStep + 1}:</b></div>
        <div className="text-box">{allSteps[currentStep]}</div>
        <div className="buttons">
            <button className="button-17" disabled={currentStep === 0}
                    onClick={() => {
                        setCurrentStep(currentStep - 1);
                        onChangeStep(currentStep - 1)
                    }}>
                Forrige steg
            </button>
            <button className="button-17" disabled={currentStep > 5}
                    onClick={() => {
                        setCurrentStep(currentStep + 1)
                        onChangeStep(currentStep + 1)
                    }}>
                Neste steg
            </button>
        </div>
    </div>
}

export default Steps;
