import React from "react";
import cx from 'classnames';

import "./Animation.css"
// @ts-ignore
import Car from "./../img/car.png"

interface AnimationProps {
    currentStep: number
}

const Animation: React.FC<AnimationProps> = ({currentStep}) => {
    return (<div className="animation">
        <div className="sidewalk">{""}</div>
        <div className="parkings">
            <div className="parking">
                <img className="car_one" src={Car} alt={"Car 1"}/>
            </div>
            <div className="parking">{""}</div>
            <div className="last_parking">
                <img className="car_two" src={Car} alt={"Car 1"}/>
            </div>
        </div>
        <img className={cx("car_with_step_" + currentStep)} id="car" src={Car} alt={"Car 1"}/>
    </div>)
}

export default Animation;
